import React from "react"
import styles from "./index.module.css"

const Agreement = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        《上海庆科信息技术有限公司智能平台服务协议》
      </h1>
      <h3>欢迎使用联每家智能平台！</h3>
      <div className={styles.describe}>
        在接受本协议之前请您务必仔细阅读本协议的全部内容，充分理解条款内容，特别是限制或免责条款，限制或免除责任条款将以加粗或其他醒目形式提示您注意。如果您对本协议的条款有疑问的，请联系联每家相关业务部门讲行询问。除非您已阅读并接受本协议及相关协议、规则等所有条款，否则，您无权使用本服务。一旦您选择“同意协议并提交”（具体措辞详见注册页面）并完成注册流程，或您以任何方式使用本服务，即视为您已阅读并同意上述协议、规则等的约束。
        如您有违反本协议的任何行为时，联每家有权根据您违反的情况随时单方采取限制、中止或终止向您提供服务等措施，并有权追究您相关责任。
      </div>
      <div className={styles.describe}>
        本服务协议（以下称“本协议”）由您与上海庆科信息技术有限公司共同签署。包括但不限于：《联每家智能平台隐私政策》等内容。如有变更，联每家将以网站公示的方式讲行公告，不另行通知。变更后的协议和规则一经公布即自动生效成为本协议的一部分，无需另行通知您同意。如您不同意相关变更，请立即停用联每家的服务；如您继续使用，则视为对修改后的规则不持异议并同意遵守。
      </div>
      <h3>一、名词定义</h3>
      <div className={styles.describe}>
        1.1.许可软件：是指由联每家开发的，供您从平台下载，并在限定的系统移动终端中安装、使用的软件系统。
      </div>
      <div className={styles.describe}>
        1.2.服务：甶上海庆科信息技术有限公司为您提供的服务。您可以通过许可软件在移动终端使用服务。
      </div>
      <h3>二、服务</h3>
      <div className={styles.describe}>
        2.1.联每家为您提供智能生活设备管理服务，您可以通过联每家控制连接到联每家云平台的智能终端，并实现智能设备之间的联动。服务内容包含智能设备管理、场景联动、分析报告等，这些功能服务可能根据用户需求的变化或服务提供方的单方判断而优化或修改，也可能会因定期、不定期的维护而暂时停止提供服务。
      </div>
      <h3>三、服务范围</h3>
      <div className={styles.describe}>
        3.1.联每家就本软件给予您一项使用本产品的权利。
      </div>
      <div className={styles.describe}>
        3.2.您不得对本产品进行任何形式的许可、出售、租赁、转让、发行或其他商业用途。
      </div>
      <div className={styles.describe}>
        3.3.甶于软件适配平台及终端限制，您仅可在获授权的系统平台及终端使用许可软件，如您将许可软件安装在其他终端设备上可能会对您硬件或软件功能造成损害。
      </div>
      <div className={styles.describe}>
        3.4.您应理解许可软件仅可用于非商业目的，不可为商业运营目的安装、使用、运行许可软件。如果需要进行须提前获得联每家的书面授权和许可。
      </div>
      <div className={styles.describe}>
        3.5.联每家会对许可软件及其相关功能不时进行变更、升级或转移，可能会在许可软件系统中加入新的功能或服务。上述新功能、服务如无独立协议的，您仍可取得相应功能与服务的授权，适用本协议。
      </div>
      <div className={styles.describe}>
        3.6.您应对输入数据的准确性、可靠性、完整性和合法性以及您获得数据的方式的合法性负责，并应定期备份数据、资料等。您对该等资料的损坏、丢失承担全部风险。
      </div>
      <div className={styles.describe}>
        3.7.您应妥善保管您的账号、密码，如您发现您的账户存在安全隐患（包括但不限于用户密码泄露），应及时通知联每家，联每家将会协助您采取相关措施。否则，您账号下的一切行为均有您负责，并由您承担一切责任。
      </div>
      <h3>四、第三方</h3>
      <div className={styles.describe}>
        4.1.您已知晓联每家的部分服务可能使用是基于第三方提供的软件或服务而获得，该服务是为了向您提供便利而设置，是取得该第三方的合法授权的。
      </div>
      <div className={styles.describe}>
        4.2.本产品包含了第三方的部分信息和服务。联每家不控制且不对第三方的信息和服务负责。
      </div>
      <div className={styles.describe}>
        4.3.您理解联每家无法保证许可软件将会永久地使用或包含该等服务，也无法保证将来会否继续使用相同第三方提供的其他软件，同时也可能使用其他第三方的类似服务，上述相应软件或服务一旦启用，同样适用本条约定。
      </div>
      <h3>五、使用服务规范</h3>
      <div className={styles.describe}>
        5.1.您应该规范使用许可软件，以下方式是违反使用规范的：
        1）发布或分享电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；
        2）未授权的情況下，收集其他用户的信息或数据，例如电子邮箱地址等；
        3）用自动化的方式恶意使用本产品，绐服务器造成过度的负担或以其他方式干扰或损害网站服务器和网络链接；
        4）在未授杈的情况下，尝试访问本产品的服务器数据或通信数据；
        5）干扰、破坏本产品其他用户的使用。
      </div>
      <div className={styles.describe}>
        5.2.您理解并同意： 1)
        联每家会对您是否涉嫌违反上述使用规范做出认定，并根据认定结果中止、终止对您的使用许可或采取其他依约可采取的限制措施。
        2)
        对于您使用许可软件时发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议的信息，联每家会直接予以删除。
        3)
        对于您违反上述使用规范的行为对任意第三方造成损害的，您需要以自己的名义独立承担法律责任，并应确保联每家免于因此产生损失或增加费用，否则联每家有权向您追偿。
        4)
        如您违反有关法律或者本协议之规定，使联每家遭受任何损失，您应当赔偿联每家因此造成的损失及（或）发生的费用。
      </div>
      <h3>六、信息内容规范</h3>
      <div className={styles.describe}>
        6.1.您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为：
      </div>
      <div className={styles.describe}>
        6.1.1.上载、传送或分享含有下列内容之一的信息： 1)
        反对宪法所确定的基本原则的； 2)
        危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 3)
        损害国家荣誉和利益的； 4) 煽动民族仇恨、民族歧视、破坏民族团结的； 5)
        破坏国家宗教政策，宣扬邪教和封建迷信的； 6)
        散布谣言，扰乱社会秩序，破坏社会稳定的； 7)
        散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； 8)
        侮辱或者诽谤他人，侵害他人合法权利的； 9)
        含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；
        10)
        含有适用法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。
      </div>
      <h3>七、隐私政策与数据</h3>
      <div className={styles.describe}>
        7.1.保护您的个人信息对联每家很重要。联每家制定了《联每家智能平台隐私政策》对知识产权归属及保护、您的信息收集、使用、共享、存储、保护等方面关系内容进行了重要披露。建议您完整地阅读《联每家智能平台隐私政策》。
      </div>
      <h3>八、免责条款</h3>
      <div className={styles.describe}>
        8.1.除法律法规有明确规定外，联每家将尽最大努力确保许可软件及其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术，联每家不能对此进行担保。
      </div>
      <div className={styles.describe}>
        8.2.您理解，对于不可抗力及第三方原因导致的您的直接或间接损失，联每家无法承担责任。
      </div>
      <div className={styles.describe}>
        8.3.甶于您因下述任一情况所引起或与此有关的人身伤害或附带的、间接的损害赔偿需由您自行承担：
        1) 第三方未经批准的使用许可软件或更改您的数据； 2)
        使用许可软件进行的行为产生的费用及损失； 3) 您对许可软件的误解； 4)
        非因联每家的原因而引起的与许可软件有关的其它损失。
      </div>
      <div className={styles.describe}>
        8.4.非经联每家或联每家授权开发并正式发布的其它任何甶许可软件衍生的软件均属非法，下载、安装、使用此类软件，可能导致不可预知的风险，甶此产生的法律责任与纠纷与联每家无关，联每家有权中止、终止使用许可和/或其他一切服务。
      </div>
      <div className={styles.describe}>
        8.5.您已知悉使用联每家平台涉及到互联网服务，可能会受到各个环节不稳定因素的影响，尽管联每家已采取安全防卫措施，本服务仍可能因互联网和电子通信固有的缺陷以及本协议任何一方无法合理控制的时间（包括不限于火灾、洪水、恐怖袭击、瘟疫、自然灾害、暴乱、终端病毒、黑客攻击、网络故障、终端故障）而发生中止、中断、延误、使用限制或使用不能。您在此特意承担以上风险，同意因上述风险事件的实际发生影响到服务的正常运行时联每家无须向您承担任何责任。
      </div>
      <h3>九、协议终止和违约责任</h3>
      <div className={styles.describe}>
        9.1.您应理解使用联每家服务需按授权范围使用许可软件、尊重软件及软件包含内容的知识产权、按规范使用软件、按本协议约定履行义务。如您严重违反本协议，联每家将终止使用许可。
      </div>
      <div className={styles.describe}>
        9.2.您对软件的使用有赖于联每家关联公司为您提供的配套服务，您若违反与联每家及关联公司的条款、协议、规则、通告等相关规定都可能导致您无法正常使用许可软件，联每家有权终止使用许可。
        或协议约定的方式对您的使用许可及其他联每家可控制的权益采取限制措施，包括中止或终止对您的使用许可。
      </div>
      <div className={styles.describe}>
        9.3.一旦您违反本协议或与联每家签订的其他协议的约定，联每家可通知联每家关联公司，要求其对您的权益采取限制措施，包括要求关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的网站依法公示您的违约情况。
      </div>
      <div className={styles.describe}>
        9.4.许可软件由您自下载平台下载取得，您需要遵守下载平台、系统平台、终端厂商对您使用许可软件方式与限制的约定，如果上述第三方确认您违反该约定需要联每家处理的，联每家可能会因第三方要求终止对您的使用许可。
      </div>
      <div className={styles.describe}>
        9.5.在本使用许可终止时，您应停止使用许可软件行为并销毁全部副本。
      </div>
      <div className={styles.describe}>
        9.6.如您违反本协议规定的条款，给联每家或其他用户造成损失，您必须承担全部赔偿责任。
      </div>
      <h3>十、管辖法律和可分割性</h3>
      <div className={styles.describe}>
        10.1.本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和(或)行业惯例。
      </div>
      <div className={styles.describe}>
        10.2.因本协议产生或与本协议有关的争议，您可与联每家以友好协商的方式予以解决或提交本协议签订地
      </div>
      <div className={styles.describe}>
        10.3.当本协议任何条款被有管辖权的人民法院裁定为无效，不影响其他条款或其任何部分的效力，您与联每家仍应善意履行。
      </div>
    </div>
  )
}

export default Agreement
